.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 30px !important;
  --swiper-pagination-color: #00008d;
  --swiper-theme-color: #00008d;
  img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
}

.swiper-pagination {
  position: absolute;
  bottom: -25px; 
  left: 0;
  width: 100%;
  text-align: center;
}

.swiper-button-disabled {
  opacity: 0.5;
}